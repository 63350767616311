<template>
    <ToastProvider>
        <v-app-bar elevation="0" absolute>
            <v-app-bar-title>
                Please talk
            </v-app-bar-title>
            <v-btn v-if="config.public.enableWaitlist" variant="elevated" color="primary"
                :to="{ name: 'index', hash: '#join-the-waitlist' }">Join
                waitlist</v-btn>
            <v-btn v-else variant="elevated" color="primary" :to="{ name: 'chat' }">Start talking</v-btn>
        </v-app-bar>
        <v-main>
            <v-container>
                <slot />
            </v-container>
        </v-main>
    </ToastProvider>
    <Footer />
</template>

<script setup lang="ts">
const config = useRuntimeConfig();
</script>